import React, { useState, useContext, useEffect, memo, createRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
} from "@mui/material";

import RowAgregarPagoAdicional from "./PagoAdicional/RowAgregarPagoAdicional";
import RowPagoAdicional from "./PagoAdicional/RowPagoAdicional";
import Draggable from "react-draggable";
import {
  AddCircle,
  Cancel,
  Delete,
  CloudUpload,
  Map,
  DeleteForever,
  Search,
} from "@mui/icons-material";
import DialogMap from "../../../../../components/Extra/DialogMap";
import { Autocomplete, IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../../config/axios";
import {
  validarDocumento,
  validarEmail,
} from "../../../../../config/validaciones";
import { replaceCaracteres, trimPropsItem } from "../../../../../config/const";
import { Download } from "@mui/icons-material";

import MuiPhoneNumber from "material-ui-phone-number";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import ToolBarTable from "./ToolBarTable";
import TablaCabecera from "./TablaCabecera";
import { isValidNumber } from "libphonenumber-js";
import { useModalStyle } from "../../../../../styles/styleModal";
import CampoDoc from "../../../../components/Campos/CampoDoc";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,

    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const [clienteProveedor, setclienteProveedor] = useState("CLIENTE");
  const [clientesData, setclientesData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    estadoRequest,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    disabled,
    desdeActa,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [paises, setpaises] = useState(null);
  const [provincias, setprovincias] = useState([]);
  const [cantones, setcantones] = useState([]);
  const [planesData, setplanesData] = useState(null);
  const [openMap, setopenMap] = useState(false);
  const [vendedoresData, setvendedoresData] = useState(null);
  const [tecnicosData, settecnicosData] = useState(null);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [PagoAdicionalsData, setPagoAdicionalsData] = useState([]);
  const [ciudadesData, setciudadesData] = useState(null);
  const [sectoresData, setsectoresData] = useState([]);
  const [ciudadesData2, setciudadesData2] = useState(null);
  const [sectoresData2, setsectoresData2] = useState([]);
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  // TABLA
  const [agregandoPagoAdicional, setagregandoPagoAdicional] = useState(false);
  const [pagePagoAdicional, setPagePagoAdicional] = React.useState(0);
  const [
    rowsPerPagePagoAdicional,
    setRowsPerPagePagoAdicional,
  ] = React.useState(10);
  const handleChangePagePagoAdicional = async (event, newPage) => {
    setPagePagoAdicional(newPage);
  };

  const handleChangeRowsPerPagePagoAdicional = (event) => {
    setRowsPerPagePagoAdicional(+event.target.value);
    setPagePagoAdicional(0);
  };

  const [documentoSeleccionado, setdocumentoSeleccionado] = useState("");
  //documento
  const [documentoperDocumento, setdocumentoperDocumento] = useState("");
  const refArchivoinvPermisoPdf = createRef();
  const focusInputinvPermisoPdf = () => {
    refArchivoinvPermisoPdf.current.click();
  };

  const onChangeArchivoinvPermisoPdf = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoperDocumento("");
      return mostrarAlerta("El archivo debe ser un PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();

      if (perDocumento === "") {
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        await formDataLogos.append("archivoAnterior", perDocumento);
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );

      // const documentosUrls = {};
      // resDocumentos.data.urls.forEach((dataUrl) => {

      //   if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
      //     return;
      //   }
      //   documentosUrls[dataUrl.nombre] = dataUrl.url;
      // });

      setitem({
        ...item,
        perDocumento: resDocumentos.data.nombre,
      });

      setdocumentoperDocumento(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoperDocumento(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoperDocumento(e.target.files[0]);
  };

  //documento
  const [
    documentoperDocumentoContrato,
    setdocumentoperDocumentoContrato,
  ] = useState("");

  const refArchivoDocumentoContrato = createRef();
  const focusInputDocumentoContrato = () => {
    refArchivoDocumentoContrato.current.click();
  };

  const onChangeArchivoDocumentoContrato = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (
      !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
    ) {
      setdocumentoperDocumentoContrato("");
      return mostrarAlerta("El archivo debe ser un PDF", "error");
    }

    try {
      setcargando(true);
      const formDataLogos = new FormData();

      if (
        item.perISPContratos[documentoSeleccionado].perDocumentoContrato === ""
      ) {
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        await formDataLogos.append(
          "archivoAnterior",
          item.perISPContratos[documentoSeleccionado].perDocumentoContrato
        );
        await formDataLogos.append("tipo", "documentos");
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      const resDocumentos = await clienteAxios.post(
        "/upload_delete_multiple_files/upload",
        formDataLogos
      );

      // const documentosUrls = {};
      // resDocumentos.data.urls.forEach((dataUrl) => {

      //   if (dataUrl.nombre === "fotoperfil" || dataUrl.nombre === "logo") {
      //     return;
      //   }
      //   documentosUrls[dataUrl.nombre] = dataUrl.url;
      // });

      handleChangeContrato(
        {
          perDocumentoContrato: resDocumentos.data.nombre,
        },
        true
      );
      setdocumentoperDocumentoContrato(e.target.files[0]);
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentoperDocumentoContrato(e.target.files[0]);

      return mostrarAlerta("Hubo un error", "error");
    }
    setdocumentoperDocumentoContrato(e.target.files[0]);
  };

  // DOCUMENTO CONTRATO

  const handleEliminar = () => {
    const propsRes = item.perISPContratos.filter((propsItem, index) => {
      return index !== Number(documentoSeleccionado);
    });
    setdocumentoSeleccionado("");
    const newObject = [];

    setitem({
      ...item,
      perISPContratos: propsRes,
    });
  };
  useEffect(() => {
    setclienteProveedor(estadoRequest);
  }, [estadoRequest]);

  useEffect(() => {
    if (!paises) {
      const getDataPaises = async () => {
        try {
          const resPaises = await clienteAxios.get(`/paises/0`);
          setpaises(resPaises.data.data);
        } catch (error) {
          setpaises([]);
        }
      };
      getDataPaises();
    }
    if (!planesData) {
      const getDataplanesData = async () => {
        try {
          const resplanesData = await clienteAxios.get(`/planes/filtro/0`);

          setplanesData(resplanesData.data.data);
        } catch (error) {
          setplanesData([]);
        }
      };
      getDataplanesData();
    }

    if (!tecnicosData) {
      const getrestecnicosData = async () => {
        try {
          const restecnicosData = await clienteAxios.get(
            `/proveedores/filtro/0?search&estado=TECNICO&tabla=personas&listadoporperfil=true`
          );

          settecnicosData(restecnicosData.data.data);
        } catch (error) {
          settecnicosData([]);
        }
      };
      getrestecnicosData();
    }

    if (!vendedoresData) {
      const getvendedoresData = async () => {
        try {
          const resvendedoresData = await clienteAxios.get(
            `/proveedores/filtro/0?search&estado=VENDEDOR&tabla=personas&listadoporperfil=true`
          );

          setvendedoresData(resvendedoresData.data.data);
        } catch (error) {
          setvendedoresData([]);
        }
      };
      getvendedoresData();
    }

    if (!ciudadesData) {
      const getciudadesData = async () => {
        try {
          const resciudadesData = await clienteAxios.get(`/ciudades/filtro/0`);

          setciudadesData(resciudadesData.data.data);
          setciudadesData2(resciudadesData.data.data);
        } catch (error) {
          setciudadesData([]);
          setciudadesData2([]);
        }
      };
      getciudadesData();
    }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const {
    // perNombreComercial,
    perDocTipo,
    perDocNumero,
    // perPerfil,
    perNombre,
    perDireccion,
    perObligado,
    perCredito,
    perTelefono,
    perCelular,
    perEstado,
    perObsevacion,
    perEmail,
    perPais,
    perProvincia,
    perCanton,
    perTipoProveedor,
    perTiempoCredito,
    perOtros,
    // perFecContrato,
    // perFecInstalacion,
    // perDiaCobro,
    // perTipoPlan,
    // perNombrePlan,
    // perValorPlan,
    // perServicioAdicional,
    // perDescuentos,
    // perFacturaAutomatica,
    // perVendedor1,
    // perVendedor2,
    // perObservacionIsp,
    // perTipoDocumento,
    // perTecnico1,
    // perTecnico2,
    // perMegas,
    perUbicacion,
    perDocumento,
    perFecNacimiento,
    // perNickname,
    // errorAutorizacion,perISPContratos
    perISPContratos,
    validateEmail,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    perDocTipo: false,
    perDocNumero: false,
    perNombre: false,
    perDireccion: false,
    perTelefono: false,
    perPais: false,
    perProvincia: false,
    perCanton: false,
    perTipoProveedor: false,
    perObligado: false,
    perTiempoCredito: false,
    perCredito: false,
    perEmail: false,
  });
  useEffect(() => {
    if (editar && open) {
      const getDataState = async () => {
        const res = await clienteAxios.get(
          `/pagosadicionales/${ObjectoActivo.perDocNumero}`
        );

        setPagoAdicionalsData(res.data.data);
      };

      getDataState();
    }
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  useEffect(() => {
    if (editar) {
      setdocSearch(ObjectoActivo.perDocNumero);
      setitem({
        ...ObjectoActivo,
        validateEmail: ObjectoActivo.validateEmail || "",
      });

      try {
        const getDataState = async () => {
          try {
            const paisF = paises.find((itemFind) => {
              return itemFind.paisNombre === ObjectoActivo.perPais;
            });
            const provinciasRes = await clienteAxios.get(
              `/provincias/filtro/${paisF.paisId}`
            );
            const provinciaF = provinciasRes.data.data.find(
              (itemProvincia) =>
                itemProvincia.provNombre === ObjectoActivo.perProvincia
            );
            const cantonesRes = await clienteAxios.get(
              `/cantones/filtro/${provinciaF.provId}`
            );
            setprovincias(provinciasRes.data.data);
            setcantones(cantonesRes.data.data);
          } catch (error) {
            setprovincias([]);
            setcantones([]);
          }
        };
        if (paises) {
          getDataState();
        }
      } catch (error) {}
    } else {
      setitem({
        ...ObjectoActivo,
        perDocTipo: clienteProveedor === "USUARIO" ? "PASAPORTE" : "RUC",
        perOtros: ObjectoActivo.perOtros ? ObjectoActivo.perOtros : [],
        validateEmail: ObjectoActivo.validateEmail || "",
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setdocSearch("");
    setPagoAdicionalsData([]);
    changeObjetoActivoState({
      perISPContratos: [],
      perNombreComercial: "",
      perEmpresa: [],
      perPais: "",
      perProvincia: "",
      perCanton: "",
      perTipoProveedor: "BIENES",
      perTiempoCredito: "0",
      perDocTipo: "",
      perDocNumero: "",
      perPerfil: [],
      perNombre: "",
      perDireccion: "",
      perObligado: "NO",
      perCredito: "NO",
      perTelefono: "0",
      perCelular: ["+593"],
      perEstado: "ACTIVO",
      perObsevacion: "",
      perEmail: ["sin@sincorreo.com"],
      perOtros: ["ZZZ9999"],
      perNickname: "",
      perUser: "",
      perFecReg: "",
      perFoto: "",
      perUbicacion: {
        longitud: "",
        latitud: "",
      },
      perDocumento: "",
      perGenero: "M",
      perRecomendacion: "OTROS",
      perFecNacimiento: "",
      perEspecialidad: "",
      perTitulo: "",
      perSenescyt: "",
      perPersonal: "INTERNO",
      validateEmail: 1,
    });
    setopen(false);
    seteditar(false);
    seterror({
      perDocTipo: false,
      perDocNumero: false,
      perNombre: false,
      perDireccion: false,
      perTelefono: false,
      perPais: false,
      perProvincia: false,
      perCanton: false,
      perTipoProveedor: false,
      perObligado: false,
      perTiempoCredito: false,
      perCredito: false,
      perEmail: false,
    });
    setseccionActive({
      datosPersonalesSeccion: true,
      datosContablesSeccion: false,
      datosISP: false,
    });
    setcargando(false);
    setdocumentoperDocumento("");
    setdocumentoSeleccionado("");
    setsectoresData([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  const handleChangeContrato = (e, esObjeto) => {
    if (esObjeto) {
      setitem({
        ...item,
        perISPContratos: item.perISPContratos.map((contrato, index) =>
          index === Number(documentoSeleccionado)
            ? trimPropsItem({
                ...contrato,
                ...e,
              })
            : contrato
        ),
      });
    } else {
      const res = replaceCaracteres(e.target.value);

      setitem({
        ...item,
        perISPContratos: item.perISPContratos.map((contrato, index) =>
          index === Number(documentoSeleccionado)
            ? {
                ...contrato,
                [e.target.name]: res,
              }
            : contrato
        ),
      });
    }
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    datosPersonalesSeccion: true,
    datosContablesSeccion: false,
    datosISP: false,
    datosPagosAdicionales: false,
  });
  const {
    datosPersonalesSeccion,
    datosContablesSeccion,
    datosISP,
    datosPagosAdicionales,
  } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validarDocumento(perDocTipo, perDocNumero, mostrarAlerta)) {
      return;
    }

    if (clienteProveedor === "CLIENTE") {
      const fecContratoVacio = item.perISPContratos.some(
        (contrato) =>
          contrato.perFecContrato === "" || contrato.perFecInstalacion === ""
      );
      if (fecContratoVacio) {
        seterror({ perFecContrato: true });
        return mostrarAlerta(
          "Las fechas de contrato e instalación son e obligatorias",
          "error"
        );
      }

      if (
        item.perDocTipo.trim() === "" ||
        item.perDocNumero.trim() === "" ||
        item.perNombre.trim() === "" ||
        item.perDireccion.trim() === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        item.perOtros.length === 0 ||
        perCelular.some((celular) => !isValidNumber(celular)) ||
        perCelular.length === 0
      ) {
        return seterror({
          perCelular:
            perCelular.some((celular) => !isValidNumber(celular)) ||
            perCelular.length === 0,
          perDocTipo: item.perDocTipo.trim() === "" ? true : false,
          perDocNumero: item.perDocNumero.trim() === "" ? true : false,
          perNombre: item.perNombre.trim() === "" ? true : false,
          perDireccion: item.perDireccion.trim() === "" ? true : false,

          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
          perOtros: item.perOtros.length === 0 ? true : false,
        });
      }
    } else {
      if (
        item.perDocTipo.trim() === "" ||
        item.perDocNumero.trim() === "" ||
        item.perNombre.trim() === "" ||
        item.perDireccion.trim() === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perTipoProveedor.trim() === "" ||
        item.perObligado.trim() === "" ||
        item.perTiempoCredito.trim() === "" ||
        item.perCredito.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        perCelular.some((celular) => !isValidNumber(celular)) ||
        perCelular.length === 0
      ) {
        return seterror({
          perCelular:
            perCelular.some((celular) => !isValidNumber(celular)) ||
            perCelular.length === 0,
          perDocTipo: item.perDocTipo.trim() === "" ? true : false,
          perDocNumero: item.perDocNumero.trim() === "" ? true : false,
          perNombre: item.perNombre.trim() === "" ? true : false,
          perDireccion: item.perDireccion.trim() === "" ? true : false,

          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perTipoProveedor: item.perTipoProveedor.trim() === "" ? true : false,
          perObligado: item.perObligado.trim() === "" ? true : false,
          perTiempoCredito: item.perTiempoCredito.trim() === "" ? true : false,
          perCredito: item.perCredito.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
        });
      }
    }

    try {
      setcargando(true);

      if (item.perId) {
        changeObjetoActivoState({
          ...ObjectoActivo,
          changeObjetoActivoState: true,
        });
        console.log(
          trimPropsItem({
            ...item,
            perISPContratos: item.perISPContratos || [],
            validateEmail: item.validateEmail || 1,
            existeEnLaTabla: item.perPerfil.some((item) => {
              if (clienteProveedor === "CLIENTE") {
                return item === "CLIENTE";
              } else {
                return item === "PROVEEDOR";
              }
            }),
            perISPContratosOld: ObjectoActivo.perISPContratos || [],
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: usuario.usuario,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil:
              clienteProveedor === "CLIENTE"
                ? [...new Set([...item.perPerfil, "CLIENTE"])]
                : clienteProveedor === "PROVEEDOR"
                ? [...new Set([...item.perPerfil, "PROVEEDOR"])]
                : clienteProveedor === "ADMINISTRACION"
                ? [...new Set([...item.perPerfil, "ADMINISTRACION"])]
                : clienteProveedor === "USUARIO"
                ? [...new Set([...item.perPerfil, "USUARIO"])]
                : clienteProveedor === "TECNICO"
                ? [...new Set([...item.perPerfil, "TECNICO"])]
                : [...new Set([...item.perPerfil, "VENDEDOR"])],
          })
        );
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            perISPContratos: item.perISPContratos || [],
            validateEmail: item.validateEmail || 1,
            existeEnLaTabla: item.perPerfil.some((item) => {
              if (clienteProveedor === "CLIENTE") {
                return item === "CLIENTE";
              } else {
                return item === "PROVEEDOR";
              }
            }),
            perISPContratosOld: ObjectoActivo.perISPContratos || [],
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: usuario.usuario,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil:
              clienteProveedor === "CLIENTE"
                ? [...new Set([...item.perPerfil, "CLIENTE"])]
                : clienteProveedor === "PROVEEDOR"
                ? [...new Set([...item.perPerfil, "PROVEEDOR"])]
                : clienteProveedor === "ADMINISTRACION"
                ? [...new Set([...item.perPerfil, "ADMINISTRACION"])]
                : clienteProveedor === "USUARIO"
                ? [...new Set([...item.perPerfil, "USUARIO"])]
                : clienteProveedor === "TECNICO"
                ? [...new Set([...item.perPerfil, "TECNICO"])]
                : [...new Set([...item.perPerfil, "VENDEDOR"])],
          })
        );
      } else {
        changeObjetoActivoState({
          ...ObjectoActivo,
          changeObjetoActivoState: true,
        });
        console.log(
          trimPropsItem({
            ...item,
            perISPContratos: item.perISPContratos || [],
            validateEmail: item.validateEmail || 1,
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            perISPContratosOld: ObjectoActivo.perISPContratos || [],
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: usuario.usuario,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil:
              clienteProveedor === "CLIENTE"
                ? [...new Set([...item.perPerfil, "CLIENTE"])]
                : clienteProveedor === "PROVEEDOR"
                ? [...new Set([...item.perPerfil, "PROVEEDOR"])]
                : clienteProveedor === "ADMINISTRACION"
                ? [...new Set([...item.perPerfil, "ADMINISTRACION"])]
                : clienteProveedor === "USUARIO"
                ? [...new Set([...item.perPerfil, "USUARIO"])]
                : clienteProveedor === "TECNICO"
                ? [...new Set([...item.perPerfil, "TECNICO"])]
                : [...new Set([...item.perPerfil, "VENDEDOR"])],
          })
        );
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...item,
            perISPContratos: item.perISPContratos || [],
            validateEmail: item.validateEmail || 1,
            perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
            perISPContratosOld: ObjectoActivo.perISPContratos || [],
            tabla: "proveedor",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            perUser: usuario.usuario,
            perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
            perPerfil:
              clienteProveedor === "CLIENTE"
                ? [...new Set([...item.perPerfil, "CLIENTE"])]
                : clienteProveedor === "PROVEEDOR"
                ? [...new Set([...item.perPerfil, "PROVEEDOR"])]
                : clienteProveedor === "ADMINISTRACION"
                ? [...new Set([...item.perPerfil, "ADMINISTRACION"])]
                : clienteProveedor === "USUARIO"
                ? [...new Set([...item.perPerfil, "USUARIO"])]
                : clienteProveedor === "TECNICO"
                ? [...new Set([...item.perPerfil, "TECNICO"])]
                : [...new Set([...item.perPerfil, "VENDEDOR"])],
          })
        );
      }

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validarDocumento(perDocTipo, perDocNumero, mostrarAlerta)) {
      return;
    }
    if (clienteProveedor === "CLIENTE") {
      const fecContratoVacio = item.perISPContratos.some((contrato) => {
        return (
          contrato.perFecContrato === "" || contrato.perFecInstalacion === ""
        );
      });
      if (fecContratoVacio) {
        seterror({ perFecContrato: true });
        return mostrarAlerta(
          "Las fechas de contrato e instalación son e obligatorias",
          "error"
        );
      }
      if (
        item.perDocTipo.trim() === "" ||
        item.perDocNumero.trim() === "" ||
        item.perNombre.trim() === "" ||
        item.perDireccion.trim() === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        item.perOtros.length === 0 ||
        perCelular.some((celular) => !isValidNumber(celular)) ||
        perCelular.length === 0
      ) {
        return seterror({
          perCelular:
            perCelular.some((celular) => !isValidNumber(celular)) ||
            perCelular.length === 0,
          perDocTipo: item.perDocTipo.trim() === "" ? true : false,
          perDocNumero: item.perDocNumero.trim() === "" ? true : false,
          perNombre: item.perNombre.trim() === "" ? true : false,
          perDireccion: item.perDireccion.trim() === "" ? true : false,

          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
          perOtros: item.perOtros.length === 0 ? true : false,
        });
      }
    } else {
      if (
        item.perDocTipo.trim() === "" ||
        item.perDocNumero.trim() === "" ||
        item.perNombre.trim() === "" ||
        item.perDireccion.trim() === "" ||
        item.perPais.trim() === "" ||
        item.perProvincia.trim() === "" ||
        item.perCanton.trim() === "" ||
        item.perTipoProveedor.trim() === "" ||
        item.perObligado.trim() === "" ||
        item.perTiempoCredito.trim() === "" ||
        item.perCredito.trim() === "" ||
        item.perEmail.length === 0 ||
        item.perEmail.some((email) => {
          return validarEmail(email);
        }) ||
        perCelular.some((celular) => !isValidNumber(celular)) ||
        perCelular.length === 0
      ) {
        return seterror({
          perCelular:
            perCelular.some((celular) => !isValidNumber(celular)) ||
            perCelular.length === 0,
          perDocTipo: item.perDocTipo.trim() === "" ? true : false,
          perDocNumero: item.perDocNumero.trim() === "" ? true : false,
          perNombre: item.perNombre.trim() === "" ? true : false,
          perDireccion: item.perDireccion.trim() === "" ? true : false,

          perPais: item.perPais.trim() === "" ? true : false,
          perProvincia: item.perProvincia.trim() === "" ? true : false,
          perCanton: item.perCanton.trim() === "" ? true : false,
          perTipoProveedor: item.perTipoProveedor.trim() === "" ? true : false,
          perObligado: item.perObligado.trim() === "" ? true : false,
          perTiempoCredito: item.perTiempoCredito.trim() === "" ? true : false,
          perCredito: item.perCredito.trim() === "" ? true : false,
          perEmail:
            item.perEmail.length === 0 ||
            item.perEmail.some((email) => {
              return validarEmail(email);
            })
              ? true
              : false,
        });
      }
    }
    try {
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          perISPContratos: item.perISPContratos || [],
          validateEmail: item.validateEmail || 1,
          perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
          perISPContratosOld: ObjectoActivo.perISPContratos || [],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "proveedor",
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          perISPContratos: item.perISPContratos || [],
          validateEmail: item.validateEmail || 1,
          perEmail: perEmail.filter((itemEmail) => itemEmail.trim() !== ""),
          perISPContratosOld: ObjectoActivo.perISPContratos || [],
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          perUser: usuario.usuario,
          perEmpresa: [...new Set([...item.perEmpresa, usuario.rucempresa])],
          tabla: "proveedor",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  //filas por pagina

  // eslint-disable-next-line

  // 1 + 2 + 3 + 4

  if (!open) {
    return null;
  }
  if (!paises) {
    return null;
  }
  if (!planesData) {
    return null;
  }

  if (!tecnicosData) {
    return null;
  }
  if (!vendedoresData) {
    return null;
  }
  if (!ciudadesData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}{" "}
                  {clienteProveedor === "CLIENTE" ? "CLIENTE" : null}
                  {clienteProveedor === "PROVEEDOR" ? "PROVEEDOR" : null}
                  {clienteProveedor === "ADMINISTRACION"
                    ? "ADMINISTRACION"
                    : null}
                  {clienteProveedor === "USUARIO" ? "USUARIO" : null}
                  {clienteProveedor === "TECNICO" ? "TECNICO" : null}
                  {clienteProveedor === "VENDEDORES" ? "VENDEDOR" : null}.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datosPersonalesSeccion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() =>
                      setseccionActive({ datosPersonalesSeccion: true })
                    }
                  >
                    Datos Personales
                  </Button>

                  {!desdeActa && (
                    <>
                      {usuario.empCategoria === "ISP" &&
                      clienteProveedor === "CLIENTE" ? (
                        <Button
                          variant={datosISP ? "contained" : null}
                          disableElevation
                          style={{ padding: "8px" }}
                          color="secondary"
                          onClick={() => setseccionActive({ datosISP: true })}
                        >
                          ISP
                        </Button>
                      ) : null}
                      {usuario.empCategoria === "ISP" &&
                      clienteProveedor === "CLIENTE" ? (
                        <Button
                          variant={datosPagosAdicionales ? "contained" : null}
                          disableElevation
                          style={{ padding: "8px" }}
                          color="secondary"
                          onClick={() =>
                            setseccionActive({ datosPagosAdicionales: true })
                          }
                        >
                          Adicionales
                        </Button>
                      ) : null}
                      <Button
                        variant={datosContablesSeccion ? "contained" : null}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() =>
                          setseccionActive({ datosContablesSeccion: true })
                        }
                      >
                        Otros datos
                      </Button>
                    </>
                  )}
                </Box>

                <FormControlLabel
                  control={
                    <Switch
                      checked={perEstado === "ACTIVO" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="secondary"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box className={clases.container}>
                {datosPersonalesSeccion ? (
                  <>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="perDocTipo"
                      label="Tipo Doc."
                      helperText={`Seleccione tipo de documento`}
                      value={perDocTipo}
                      name="perDocTipo"
                      error={error.perDocTipo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione un tipo de Doc.</MenuItem>
                      {["RUC", "CEDULA", "PASAPORTE"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <Box
                      className={clases.formD}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label={
                          clienteProveedor === "USUARIO"
                            ? "Usuario"
                            : "Número Doc."
                        }
                        error={error.perDocNumero}
                        value={docSearch}
                        onChange={async (e) => {
                          setdocSearch(e.target.value);
                          setitem({ ...item, perDocNumero: e.target.value });
                        }}
                        helperText={`Presione "Enter" para buscar`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={async (e) => {
                                  if (docSearch === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    setcargandoDoc(true);
                                    const res = await clienteAxios.get(
                                      `proveedores/searchByCedulaOfRuc/0?search=${docSearch}&rol=${estadoRequest}`
                                    );
                                    console.log(res);
                                    setdocumentoSeleccionado("");
                                    if (res?.data) {
                                      // changeObjetoActivoState({
                                      //   ...res.data,
                                      //   perOtros: res.data.perOtros
                                      //     ? res.data.perOtros
                                      //     : [],
                                      // });
                                      setitem({
                                        ...res.data,
                                        perOtros: res.data.perOtros
                                          ? res.data.perOtros
                                          : [],
                                      });
                                    }
                                    setcargandoDoc(false);
                                  } catch (error) {
                                    setcargandoDoc(false);
                                    console.log({ error });
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    setitem({
                                      ...ObjectoActivo,
                                      perDocNumero: docSearch,
                                    });
                                  }
                                }}
                              >
                                <Search color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={async (e) => {
                          if (e.key !== "Enter") {
                            return;
                          }
                          console.log(e.target.value);
                          if (e.target.value === "") {
                            return mostrarAlerta(
                              "Ingrese un documento",
                              "error"
                            );
                          }
                          try {
                            setcargandoDoc(true);
                            setitem({
                              ...item,
                              perDocNumero: e.target.value,
                            });
                            const res = await clienteAxios.get(
                              `proveedores/searchByCedulaOfRuc/0?search=${e.target.value}&rol=${estadoRequest}`
                            );
                            console.log(res);
                            setdocumentoSeleccionado("");
                            if (res?.data) {
                              // changeObjetoActivoState({
                              //   ...res.data,
                              //   perOtros: res.data.perOtros
                              //     ? res.data.perOtros
                              //     : [],
                              // });
                              setitem({
                                ...res.data,
                                perOtros: res.data.perOtros
                                  ? res.data.perOtros
                                  : [],
                              });
                            }
                            setcargandoDoc(false);
                          } catch (error) {
                            console.log({ error });
                            setcargandoDoc(false);
                            mostrarAlerta(error?.response?.data?.msg, "error");
                            setitem({
                              ...ObjectoActivo,
                              perDocNumero: docSearch,
                            });
                          }
                        }}
                      />
                      {cargandoDoc && (
                        <LinearProgress
                          color="primary"
                          fourColor
                          variant="query"
                        />
                      )}
                    </Box>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="perNombre"
                      label={
                        clienteProveedor === "CLIENTE"
                          ? "Nombres"
                          : "Razón Social"
                      }
                      value={perNombre}
                      name="perNombre"
                      error={error.perNombre}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: [e.target.value]
                            .toString()
                            .toUpperCase(),
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="perDireccion"
                      label="Dirección"
                      value={perDireccion}
                      name="perDireccion"
                      error={error.perDireccion}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={ciudadesData2.map((item) => {
                          return item.ciuNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={item.ciudad}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const itemF = ciudadesData.find(
                              (ciudad) => ciudad.ciuNombre === newValue
                            );
                            setsectoresData2(itemF.ciuSectores);
                            setitem({ ...item, ciudad: newValue });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Ciudad"
                            name="perUbicacionContrato"
                            error={error.perUbicacionContrato}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={sectoresData2.map((item) => {
                          return item.nombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={item.sector}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({ ...item, sector: newValue });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Sector"
                            name="perUbicacionContrato"
                            error={error.perUbicacionContrato}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="perTelefono"
                      label="Teléfono"
                      value={perTelefono}
                      name="perTelefono"
                      error={error.perTelefono}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="perFecNacimiento"
                      label="Fecha Nacimiento"
                      value={perFecNacimiento}
                      name="perFecNacimiento"
                      error={error.perFecNacimiento}
                      onChange={(e) => handleChange(e)}
                    />
                    {/*  */}
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.perCelular ? "error" : "primary"}
                        >
                          Celulares:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              perCelular: [...item.perCelular, "+593"],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {perCelular.map((celular, index) => {
                      return (
                        <MuiPhoneNumber
                          key={index}
                          inputClass={clases.formD}
                          defaultCountry={"ec"}
                          regions={"america"}
                          value={celular}
                          name="perCelular"
                          error={error.perCelular}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              perCelular: perCelular.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Celular #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      perCelular: perCelular.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}{" "}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="validateEmail"
                      label="Validar Email"
                      value={item?.validateEmail || ""}
                      name="validateEmail"
                      error={error.validateEmail}
                      onChange={(e) => {
                        setitem({ ...item, [e.target.name]: e.target.value });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      <MenuItem value={1}>SI </MenuItem>;
                      <MenuItem value={0}>NO </MenuItem>;
                    </TextField>
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.perEmail ? "error" : "primary"}
                        >
                          Correos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              perEmail: [...item.perEmail, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {perEmail.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          className={clases.formD}
                          id=""
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              perEmail: perEmail.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Correo #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      perEmail: perEmail.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={paises.map(
                          (item) => item.paisNombre + "/+/" + item.paisId
                        )}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={perPais}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const options = newValue.split("/+/");
                              const provinciasRes = await clienteAxios.get(
                                `/provincias/filtro/${options[1]}`
                              );

                              setprovincias(provinciasRes.data.data);
                              setitem({
                                ...item,
                                perPais: options[0],
                                perProvincia: "",
                                perCanton: "",
                              });
                            } catch (error) {
                              const options = newValue.split("/+/");
                              setitem({
                                ...item,
                                perPais: options[0],
                                perProvincia: "",
                                perCanton: "",
                              });
                              mostrarAlerta(error.response.data.msg, "error");
                              setprovincias([]);
                              setcantones([]);
                            }
                          } else {
                            // setrucString("");
                            // setitem({
                            //   ...item,
                            //   rucProveedor: "",
                            //   nomProveedor: "",
                            // });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="País"
                            error={error.perPais}
                            autoComplete={false}
                            fullWidth
                            onChange={async (e) => {}}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        autoComplete="false"
                        options={provincias.map(
                          (item) => item.provNombre + "/+/" + item.provId
                        )}
                        getOptionLabel={(option) => {
                          return option.split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={perProvincia}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const options = newValue.split("/+/");
                              const cantonesRes = await clienteAxios.get(
                                `/cantones/filtro/${options[1]}`
                              );

                              setcantones(cantonesRes.data.data);
                              setitem({
                                ...item,
                                perProvincia: options[0],
                                perCanton: "",
                              });
                            } catch (error) {
                              const options = newValue.split("/+/");
                              setitem({
                                ...item,
                                perProvincia: options[0],
                                perCanton: "",
                              });
                              mostrarAlerta(error.response.data.msg, "error");
                              setcantones([]);
                            }
                          } else {
                            // setrucString("");
                            // setitem({
                            //   ...item,
                            //   rucProveedor: "",
                            //   nomProveedor: "",
                            // });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            autoComplete={false}
                            label="Provincia"
                            error={error.perProvincia}
                            fullWidth
                            onChange={async (e) => {}}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={cantones.map((item) => item.canNombre)}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={perCanton}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              setitem({
                                ...item,
                                perCanton: newValue,
                              });
                            } catch (error) {}
                          } else {
                            // setrucString("");
                            // setitem({
                            //   ...item,
                            //   rucProveedor: "",
                            //   nomProveedor: "",
                            // });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            label="Cantón"
                            error={error.perCanton}
                            fullWidth
                            onChange={async (e) => {}}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <>
                      {clienteProveedor === "CLIENTE" ||
                      clienteProveedor === "USUARIO" ? (
                        <>
                          <FormControl className={clases.formAllW}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="subtitle1"
                                color={error.perOtros ? "error" : "primary"}
                              >
                                Otros:
                              </Typography>
                              <IconButton
                                aria-label=""
                                size="small"
                                onClick={async () => {
                                  setitem({
                                    ...item,
                                    perOtros: [...item.perOtros, ""],
                                  });
                                }}
                              >
                                <AddCircle fontSize="small" color="secondary" />
                              </IconButton>
                            </Box>
                          </FormControl>
                          {item.perOtros ? (
                            <>
                              {perOtros.map((precio, index) => {
                                return (
                                  <TextField
                                    key={index}
                                    className={clases.formD}
                                    id=""
                                    value={precio}
                                    onKeyDown={(e) => {
                                      if (e.key === "Backspace") {
                                        return setitem({
                                          ...item,
                                          perOtros: perOtros.map(
                                            (item, indexItem) => {
                                              if (indexItem === index) {
                                                return e.target.value.substr(
                                                  0,
                                                  e.target.value.length - 1
                                                );
                                              } else {
                                                return item;
                                              }
                                            }
                                          ),
                                        });
                                      }
                                      const validos = [
                                        "a",
                                        "b",
                                        "c",
                                        "d",
                                        "e",
                                        "f",
                                        "g",
                                        "h",
                                        "i",
                                        "j",
                                        "k",
                                        "l",
                                        "m",
                                        "n",
                                        "ñ",
                                        "o",
                                        "p",
                                        "q",
                                        "r",
                                        "s",
                                        "t",
                                        "u",
                                        "v",
                                        "w",
                                        "x",
                                        "y",
                                        "z",
                                        "A",
                                        "B",
                                        "C",
                                        "D",
                                        "E",
                                        "F",
                                        "G",
                                        "H",
                                        "I",
                                        "J",
                                        "K",
                                        "L",
                                        "M",
                                        "N",
                                        "Ñ",
                                        "O",
                                        "P",
                                        "Q",
                                        "R",
                                        "S",
                                        "T",
                                        "U",
                                        "V",
                                        "W",
                                        "X",
                                        "Y",
                                        "Z",
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "0",
                                      ];

                                      const existe = validos.find(
                                        (caracterValido) => {
                                          return caracterValido === e.key;
                                        }
                                      );

                                      if (existe) {
                                        setitem({
                                          ...item,
                                          perOtros: perOtros.map(
                                            (item, indexItem) => {
                                              if (indexItem === index) {
                                                return (
                                                  item + existe
                                                ).toUpperCase();
                                              } else {
                                                return item;
                                              }
                                            }
                                          ),
                                        });
                                      }
                                    }}
                                    onChange={(e) => {}}
                                    label={`Placa #${index + 1}`}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment>
                                          <IconButton
                                            aria-label=""
                                            onClick={() => {
                                              setitem({
                                                ...item,
                                                perOtros: perOtros.filter(
                                                  (item, indexItem) => {
                                                    return indexItem !== index;
                                                  }
                                                ),
                                              });
                                            }}
                                          >
                                            <Delete color="error" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                );
                              })}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  </>
                ) : null}
                {datosISP ? (
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" stretch"
                      width="100%"
                    >
                      <Box>
                        {/* WORKS */}
                        {Object.entries(item.perISPContratos || {}).map(
                          (itemContrato) => {
                            return (
                              <Button
                                variant={
                                  documentoSeleccionado === itemContrato[0]
                                    ? "contained"
                                    : null
                                }
                                disableElevation
                                style={{ padding: "8px" }}
                                color="primary"
                                onClick={() => {
                                  setdocumentoSeleccionado(itemContrato[0]);
                                  setdocumentoperDocumentoContrato("");
                                }}
                              >
                                - {itemContrato[1].perNombreContrato} -
                              </Button>
                            );
                          }
                        )}
                      </Box>
                      <Box>
                        <ConfirmacionDialog
                          open={openConfirmDialog}
                          setopen={setopenConfirmDialog}
                          titulo={`¿Esta seguro de eliminar este documento: ${documentoSeleccionado}?`}
                          funcion={handleEliminar}
                        />
                        {/* {JSON.stringify({ rol: usuario.rol })} */}
                        {documentoSeleccionado === "" ? null : (
                          <IconButton
                            disabled={!usuario.rol.includes("SUPERADMIN")}
                            aria-label=""
                            color="error"
                            variant
                            onClick={async () => {
                              setopenConfirmDialog(true);
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label=""
                          color="primary"
                          variant
                          onClick={() => {
                            console.log({ item });
                            const itemContrato = {
                              ...item,
                              perISPContratos: [
                                ...(item.perISPContratos || []),
                                {
                                  equipo: "ACTIVO",
                                  serviciosDigitales: 0,
                                  perEstadoContrado: "ACTIVO",
                                  perDocumentoContrato: "",
                                  perNombreContrato: "",
                                  perFecContrato: "",
                                  perFecInstalacion: "",
                                  perDiaCobro: "1",
                                  perTipoPlan: "",
                                  perNombrePlan: "",
                                  perValorPlan: "",
                                  perServicioAdicional: "0",
                                  perDescuentos: "0",
                                  perFacturaAutomatica: "NO",
                                  perVendedor1: {
                                    perId: "",
                                    perDocNumero: "",
                                    perNombre: "",
                                  },
                                  perVendedor2: {
                                    perId: "",
                                    perDocNumero: "",
                                    perNombre: "",
                                  },
                                  perObservacionIsp: "",
                                  perTipoDocumento: "NOTA",
                                  perTecnico1: {
                                    perId: "",
                                    perDocNumero: "",
                                    perNombre: "",
                                  },
                                  perTecnico2: {
                                    perId: "",
                                    perDocNumero: "",
                                    perNombre: "",
                                  },
                                  perMegas: "",
                                  perUbicacionContrato: "SANTO DOMINGO", // select => SANTO DOMINGO  || QUININDÉ
                                  perSector: "",
                                  perDireccionContrato: "", // Inpu
                                  PPPOEoIP: "",
                                },
                              ],
                            };

                            console.log({ itemContrato });
                            // setdocumentoSeleccionado(
                            //   item.perISPContratos.length
                            // );
                            setdocumentoperDocumentoContrato("");
                            setitem(itemContrato);
                          }}
                        >
                          <AddCircle />
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider style={{ width: "100%" }} />

                    {documentoSeleccionado === "" ? null : (
                      <>
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          error={error.perFacturaAutomatica}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                            >
                              Factura Automática
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => handleChangeContrato(e)}
                              row
                              aria-label="gender"
                              value={
                                perISPContratos[documentoSeleccionado]
                                  ? perISPContratos[documentoSeleccionado]
                                      .perFacturaAutomatica
                                  : ""
                              }
                              name="perFacturaAutomatica"
                            >
                              <FormControlLabel
                                value="SI"
                                control={<Radio />}
                                label="SI"
                              />
                              <FormControlLabel
                                value="NO"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          error={error.perTipoDocumento}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                            >
                              Tipo Documento
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => handleChangeContrato(e)}
                              row
                              aria-label="gender"
                              value={
                                perISPContratos[documentoSeleccionado]
                                  .perTipoDocumento
                              }
                              name="perTipoDocumento"
                            >
                              <FormControlLabel
                                value="NOTA"
                                control={<Radio />}
                                label="NOTA"
                              />
                              <FormControlLabel
                                value="FACTURA"
                                control={<Radio />}
                                label="FACTURA"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perNombreContrato"
                          label="Nombre Contrato"
                          size="small"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perNombreContrato
                          }
                          name="perNombreContrato"
                          error={error.perNombreContrato}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perEstadoContrado"
                          label="Estado"
                          size="small"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perEstadoContrado
                          }
                          InputProps={{
                            sx: {
                              color:
                                perISPContratos[documentoSeleccionado]
                                  .perEstadoContrado === "SUSPENDIDO"
                                  ? "orange"
                                  : perISPContratos[documentoSeleccionado]
                                      .perEstadoContrado ===
                                    "TERMINACION DE CONTRATO"
                                  ? "red"
                                  : "green",
                            },
                          }}
                          name="perEstadoContrado"
                          error={error.perEstadoContrado}
                          onChange={(e) => handleChangeContrato(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "ACTIVO",
                            "SUSPENDIDO",
                            "TERMINACION DE CONTRATO",
                          ].map((item) => {
                            return (
                              <MenuItem
                                sx={{
                                  color:
                                    item === "SUSPENDIDO"
                                      ? "orange"
                                      : item === "TERMINACION DE CONTRATO"
                                      ? "red"
                                      : "green",
                                }}
                                value={item}
                              >
                                {item}{" "}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {/* <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            disabled={editar}
                            options={ciudadesData.map((item) => {
                              return item.ciuNombre;
                            })}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            id="controlled-demo"
                            value={
                              perISPContratos[documentoSeleccionado]
                                .perUbicacionContrato
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                label="Ubicación"
                                value={
                                  perISPContratos[documentoSeleccionado]
                                    .perUbicacionContrato
                                }
                                name="perUbicacionContrato"
                                error={error.perUbicacionContrato}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl> */}
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            options={ciudadesData.map((item) => {
                              return item.ciuNombre;
                            })}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            id="controlled-demo"
                            value={
                              perISPContratos[documentoSeleccionado]
                                .perUbicacionContrato
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const itemF = ciudadesData.find(
                                  (ciudad) => ciudad.ciuNombre === newValue
                                );
                                setsectoresData(itemF.ciuSectores);
                                handleChangeContrato(
                                  {
                                    perUbicacionContrato: newValue,
                                    perSector: "",
                                  },
                                  true
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                label="Ciudad"
                                value={
                                  perISPContratos[documentoSeleccionado]
                                    .perUbicacionContrato
                                }
                                name="perUbicacionContrato"
                                error={error.perUbicacionContrato}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>{" "}
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            options={sectoresData.map((item) => {
                              return item.nombre;
                            })}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            id="controlled-demo"
                            value={
                              perISPContratos[documentoSeleccionado].perSector
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                handleChangeContrato({
                                  target: {
                                    name: "perSector",
                                    value: newValue,
                                  },
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                label="Sector"
                                value={
                                  perISPContratos[documentoSeleccionado]
                                    ? perISPContratos[documentoSeleccionado]
                                        .perUbicacionContrato
                                    : ""
                                }
                                name="perUbicacionContrato"
                                error={error.perUbicacionContrato}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perDireccionContrato"
                          label="Dirección"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perDireccionContrato
                          }
                          name="perDireccionContrato"
                          error={error.perDireccionContrato}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perFecContrato"
                          label="Fec. Contrato"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perFecContrato
                          }
                          name="perFecContrato"
                          error={
                            error.perFecContrato &&
                            !perISPContratos[documentoSeleccionado]
                              .perFecContrato
                          }
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perFecInstalacion"
                          label="Fec. Instalación"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perFecInstalacion
                          }
                          name="perFecInstalacion"
                          error={
                            error.perFecContrato &&
                            !perISPContratos[documentoSeleccionado]
                              .perFecInstalacion
                          }
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perDiaCobro"
                          label="Dia Cobro"
                          value={
                            perISPContratos[documentoSeleccionado].perDiaCobro
                          }
                          name="perDiaCobro"
                          error={error.perDiaCobro}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <FormControl fullWidth className={clases.formD}>
                          <Autocomplete
                            autoComplete="false"
                            options={planesData.map(
                              (item) =>
                                item.planTipo +
                                "/+/" +
                                item.planNombre +
                                "/+/" +
                                item.planValor +
                                "/+/" +
                                item.planMegas +
                                "/+/"
                            )}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " ** ")
                                .replace("/+/", " ** $")
                                .replace("/+/", " ** ")
                                .replace("/+/", " MB");
                            }}
                            id="controlled-demo"
                            value={
                              (
                                perISPContratos[documentoSeleccionado]
                                  .perTipoPlan +
                                " " +
                                perISPContratos[documentoSeleccionado]
                                  .perNombrePlan +
                                " " +
                                perISPContratos[documentoSeleccionado]
                                  .perValorPlan +
                                " " +
                                perISPContratos[documentoSeleccionado].perMegas
                              ).trim() === ""
                                ? ""
                                : perISPContratos[documentoSeleccionado]
                                    .perTipoPlan +
                                  " ** " +
                                  perISPContratos[documentoSeleccionado]
                                    .perNombrePlan +
                                  " ** $" +
                                  perISPContratos[documentoSeleccionado]
                                    .perValorPlan +
                                  " ** " +
                                  perISPContratos[documentoSeleccionado]
                                    .perMegas +
                                  " MB"
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                handleChangeContrato(
                                  {
                                    perTipoPlan: options[0],
                                    perNombrePlan: options[1],
                                    perValorPlan: options[2],
                                    perMegas: options[3],
                                  },
                                  true
                                );
                              } else {
                                handleChangeContrato(
                                  {
                                    perTipoPlan: "",
                                    perNombrePlan: "",
                                    perValorPlan: "",
                                    perMegas: "",
                                  },
                                  true
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                autoComplete={false}
                                label="Plan"
                                error={error.perTipoPlan}
                                fullWidth
                                onChange={async (e) => {}}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>{" "}
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="PPPOEoIP"
                          label="PPPOE o IP"
                          value={
                            perISPContratos[documentoSeleccionado].PPPOEoIP
                          }
                          name="PPPOEoIP"
                          error={error.PPPOEoIP}
                          onChange={(e) => handleChangeContrato(e)}
                        />{" "}
                        <TextField
                          inputProps={{
                            className: clases.inputPadding,
                          }}
                          className={clases.formD}
                          id="equipo"
                          label="Equipo"
                          value={
                            perISPContratos[documentoSeleccionado].equipo ||
                            "ACTIVO"
                          }
                          name="equipo"
                          error={error.equipo}
                          onChange={(e) => handleChangeContrato(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {["ACTIVO", "PENDIENTE", "RECUPERADO"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perServicioAdicional"
                          label="Valor Proporcional"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perServicioAdicional
                          }
                          name="perServicioAdicional"
                          error={error.perServicioAdicional}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="serviciosDigitales"
                          label="Servicios Digitales"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .serviciosDigitales
                          }
                          name="serviciosDigitales"
                          error={error.serviciosDigitales}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perDescuentos"
                          label="Descuentos"
                          value={
                            perISPContratos[documentoSeleccionado].perDescuentos
                          }
                          name="perDescuentos"
                          error={error.perDescuentos}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                        <>
                          <FormControl fullWidth className={clases.formD}>
                            <Autocomplete
                              autoComplete="false"
                              options={vendedoresData.map(
                                (item) =>
                                  item.perId +
                                  "/+/" +
                                  item.perDocNumero +
                                  "/+/" +
                                  item.perNombre
                              )}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ** ")
                                  .replace("/+/", " ** ");
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor1.perId +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor1.perDocNumero +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor1.perNombre
                                ).trim() === ""
                                  ? ""
                                  : perISPContratos[documentoSeleccionado]
                                      .perVendedor1.perId +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perVendedor1.perDocNumero +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perVendedor1.perNombre
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");
                                  handleChangeContrato(
                                    {
                                      perVendedor1: {
                                        perId: options[0],
                                        perDocNumero: options[1],
                                        perNombre: options[2],
                                      },
                                    },
                                    true
                                  );
                                } else {
                                  handleChangeContrato(
                                    {
                                      perVendedor1: {
                                        perId: "",
                                        perDocNumero: "",
                                        perNombre: "",
                                      },
                                    },
                                    true
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  autoComplete={false}
                                  label="Vendedor 1"
                                  error={error.perTipoPlan}
                                  fullWidth
                                  onChange={async (e) => {}}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>

                          <FormControl fullWidth className={clases.formD}>
                            <Autocomplete
                              autoComplete="false"
                              options={vendedoresData.map(
                                (item) =>
                                  item.perId +
                                  "/+/" +
                                  item.perDocNumero +
                                  "/+/" +
                                  item.perNombre
                              )}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ** ")
                                  .replace("/+/", " ** ");
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor2.perId +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor2.perDocNumero +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perVendedor2.perNombre
                                ).trim() === ""
                                  ? ""
                                  : perISPContratos[documentoSeleccionado]
                                      .perVendedor2.perId +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perVendedor2.perDocNumero +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perVendedor2.perNombre
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");
                                  handleChangeContrato(
                                    {
                                      perVendedor2: {
                                        perId: options[0],
                                        perDocNumero: options[1],
                                        perNombre: options[2],
                                      },
                                    },
                                    true
                                  );
                                } else {
                                  handleChangeContrato(
                                    {
                                      perVendedor2: {
                                        perId: "",
                                        perDocNumero: "",
                                        perNombre: "",
                                      },
                                    },
                                    true
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  autoComplete={false}
                                  label="Vendedor 2"
                                  error={error.perTipoPlan}
                                  fullWidth
                                  onChange={async (e) => {}}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </>
                        <>
                          <FormControl fullWidth className={clases.formD}>
                            <Autocomplete
                              autoComplete="false"
                              options={tecnicosData.map(
                                (item) =>
                                  item.perId +
                                  "/+/" +
                                  item.perDocNumero +
                                  "/+/" +
                                  item.perNombre
                              )}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ** ")
                                  .replace("/+/", " ** ");
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico1.perId +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico1.perDocNumero +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico1.perNombre
                                ).trim() === ""
                                  ? ""
                                  : perISPContratos[documentoSeleccionado]
                                      .perTecnico1.perId +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perTecnico1.perDocNumero +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perTecnico1.perNombre
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");
                                  handleChangeContrato(
                                    {
                                      perTecnico1: {
                                        perId: options[0],
                                        perDocNumero: options[1],
                                        perNombre: options[2],
                                      },
                                    },
                                    true
                                  );
                                } else {
                                  handleChangeContrato(
                                    {
                                      perTecnico1: {
                                        perId: "",
                                        perDocNumero: "",
                                        perNombre: "",
                                      },
                                    },
                                    true
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  autoComplete={false}
                                  label="Técnico 1"
                                  error={error.perTipoPlan}
                                  fullWidth
                                  onChange={async (e) => {}}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl fullWidth className={clases.formD}>
                            <Autocomplete
                              autoComplete="false"
                              options={tecnicosData.map(
                                (item) =>
                                  item.perId +
                                  "/+/" +
                                  item.perDocNumero +
                                  "/+/" +
                                  item.perNombre
                              )}
                              getOptionLabel={(option) => {
                                return option
                                  .replace("/+/", " ** ")
                                  .replace("/+/", " ** ");
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico2.perId +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico2.perDocNumero +
                                  " " +
                                  perISPContratos[documentoSeleccionado]
                                    .perTecnico2.perNombre
                                ).trim() === ""
                                  ? ""
                                  : perISPContratos[documentoSeleccionado]
                                      .perTecnico2.perId +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perTecnico2.perDocNumero +
                                    " ** " +
                                    perISPContratos[documentoSeleccionado]
                                      .perTecnico2.perNombre
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  const options = newValue.split("/+/");
                                  handleChangeContrato(
                                    {
                                      perTecnico2: {
                                        perId: options[0],
                                        perDocNumero: options[1],
                                        perNombre: options[2],
                                      },
                                    },
                                    true
                                  );
                                } else {
                                  handleChangeContrato(
                                    {
                                      perTecnico2: {
                                        perId: "",
                                        perDocNumero: "",
                                        perNombre: "",
                                      },
                                    },
                                    true
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  autoComplete={false}
                                  label="Técnico 2"
                                  error={error.perTipoPlan}
                                  fullWidth
                                  onChange={async (e) => {}}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <>
                            <CampoDoc
                              disabled={cargando}
                              disabledE={cargando}
                              setcargando={setcargando}
                              label={"Documento"}
                              propiedad={"perDocumentoContrato"}
                              item={perISPContratos[documentoSeleccionado]}
                              setitem={(e) => {
                                handleChangeContrato(e, true);
                              }}
                              celda={false}
                            />
                          </>
                        </>
                        <TextField
                          type="text"
                          variant="outlined"
                          multiline
                          minRows={1}
                          maxRows={3}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          id="perObservacionIsp"
                          label="Observación"
                          value={
                            perISPContratos[documentoSeleccionado]
                              .perObservacionIsp
                          }
                          name="perObservacionIsp"
                          error={error.perObservacionIsp}
                          onChange={(e) => handleChangeContrato(e)}
                        />
                      </>
                    )}
                  </>
                ) : null}
                {datosPagosAdicionales ? (
                  <>
                    <Paper className={clases.root} sx={{ width: "100%" }}>
                      <ToolBarTable
                        titulo={"Pago Adicional"}
                        soloTitulo={true}
                        botonAdd={true}
                        deshabilitarbotonAdd={false}
                        funcionAdd={() => {
                          setagregandoPagoAdicional(true);
                        }}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[
                                  10,
                                  20,
                                  50,
                                  100,
                                  500,
                                  1000,
                                ]}
                                component="div"
                                count={PagoAdicionalsData.length}
                                rowsPerPage={rowsPerPagePagoAdicional}
                                page={pagePagoAdicional}
                                onPageChange={handleChangePagePagoAdicional}
                                onRowsPerPageChange={
                                  handleChangeRowsPerPagePagoAdicional
                                }
                              />
                            </Box>
                          </caption>

                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "padiContrato",
                                  label: "Contrato",
                                  minWidth: 100,
                                },
                                {
                                  id: "padiDetalle",
                                  label: "Detalle",
                                  minWidth: 150,
                                },
                                {
                                  id: "padiValor",
                                  label: "Valor",
                                  minWidth: 50,
                                },
                                {
                                  id: "padiIva",
                                  label: "Iva",
                                  minWidth: 50,
                                },
                                {
                                  id: "padiFecCobro",
                                  label: "Fec. Cobro",
                                  minWidth: 100,
                                },
                                {
                                  id: "usuario",
                                  label: "Usuario",
                                  minWidth: 100,
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoPagoAdicional ? (
                              <RowAgregarPagoAdicional
                                agregar={agregandoPagoAdicional}
                                setagregar={setagregandoPagoAdicional}
                                setPagoAdicionalsData={setPagoAdicionalsData}
                                PagoAdicionalsData={PagoAdicionalsData}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {PagoAdicionalsData.slice(
                              pagePagoAdicional * rowsPerPagePagoAdicional,
                              pagePagoAdicional * rowsPerPagePagoAdicional +
                                rowsPerPagePagoAdicional
                            ).map((row, index) => {
                              return (
                                <RowPagoAdicional
                                  setPagoAdicionalsData={setPagoAdicionalsData}
                                  PagoAdicionalsData={PagoAdicionalsData}
                                  item={item}
                                  setitem={setitem}
                                  row={row}
                                  index={index}
                                  key={row.padiId}
                                />
                              );
                            })}
                            <TableRow>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell size="small" align="center">
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  $
                                  {PagoAdicionalsData.length === 0
                                    ? null
                                    : PagoAdicionalsData.map((row) => {
                                        return Number(row.padiValor);
                                      })
                                        .reduce(reducer)
                                        .toFixed(2)}
                                </Typography>
                              </TableCell>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                              <TableCell
                                size="small"
                                align="center"
                              ></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
                {datosContablesSeccion ? (
                  <>
                    {clienteProveedor === "CLIENTE" ||
                    clienteProveedor === "PROVEEDOR" ? (
                      <FormControl
                        component="fieldset"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FormLabel
                            component="span"
                            color="primary"
                            style={{ marginRight: ".5rem", fontWeight: "bold" }}
                            className={error.perObligado ? clases.error : ""}
                          >
                            Obligado
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) => handleChange(e)}
                            row
                            aria-label="gender"
                            value={perObligado}
                            name="perObligado"
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                    ) : null}
                    {clienteProveedor === "CLIENTE" ||
                    clienteProveedor === "USUARIO" ? null : (
                      <>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perTipoProveedor"
                          label="Tipo proveedor"
                          value={perTipoProveedor}
                          name="perTipoProveedor"
                          error={error.perTipoProveedor}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {["BIENES", "SERVICIOS", "BIENES / SERVICIOS"].map(
                            (item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            }
                          )}
                        </TextField>
                        <TextField
                          type="number"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          id="perTiempoCredito"
                          label="Tiempo Crédito(Días)."
                          value={perTiempoCredito}
                          name="perTiempoCredito"
                          error={error.perTiempoCredito}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                              className={error.perCredito ? clases.error : ""}
                            >
                              Crédito
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => handleChange(e)}
                              row
                              aria-label="gender"
                              value={perCredito}
                              name="perCredito"
                            >
                              <FormControlLabel
                                value="SI"
                                control={<Radio />}
                                label="SI"
                              />
                              <FormControlLabel
                                value="NO"
                                control={<Radio />}
                                label="NO"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>
                      </>
                    )}
                    <>
                      <CampoDoc
                        disabled={cargando}
                        disabledE={cargando}
                        setcargando={setcargando}
                        label={"Documento"}
                        propiedad={"perDocumento"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                    </>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      size="small"
                      id="latitud"
                      label="Ubicación GPS"
                      value={
                        perUbicacion.latitud + ", " + perUbicacion.longitud
                      }
                      name="latitud"
                      error={error.latitud}
                      InputProps={{
                        endAdornment: (
                          <Box htmlFor="contained-button-documento" fullWidth>
                            <InputAdornment>
                              <Tooltip title="Mostrar Mapa" arrow>
                                <Box>
                                  <IconButton
                                    aria-label=""
                                    onClick={() => setopenMap(true)}
                                  >
                                    <Map color="secondary" />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                    />
                    <DialogMap
                      open={openMap}
                      setopen={setopenMap}
                      campo={"perUbicacion"}
                      setitem={setitem}
                      item={item}
                      editar={editar}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      id="perObsevacion"
                      label="Observación"
                      value={perObsevacion}
                      multiline
                      rows={3}
                      variant={"outlined"}
                      name="perObsevacion"
                      error={error.perObsevacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando || disabled}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
